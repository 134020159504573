// This file was automatically generated from manager.manage.membershipgroups.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }


Nightloop.Templates.Manager.Manage.MembershipGroups = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.MembershipGroupsBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.MembershipGroupsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'membershipgroups'}), output);
  output.append('<div id="page-membership-groups" class="has-sidebar"><div id="main-area"><div id="content-header"><h2>MEMBERSHIP GROUPS</h2><p class="button"><a id="add-membership-group" href="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/membershipgroups/create">Add Membership Group</a></p></div>', (opt_data.content.message) ? '<div class="global-message success fade">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '', '<div class="standard-list"><div class="list-block">');
  var groupList617 = opt_data.content.groups;
  var groupListLen617 = groupList617.length;
  for (var groupIndex617 = 0; groupIndex617 < groupListLen617; groupIndex617++) {
    var groupData617 = groupList617[groupIndex617];
    Nightloop.Templates.Manager.Manage.MembershipGroupRow(soy.$$augmentData(opt_data, {group: groupData617}), output);
  }
  output.append('</div></div></div></div><script type="text/javascript">$(function() {MembershipGroupList.init(\'', soy.$$escapeHtml(opt_data.venue.manager_base_url), '\');});<\/script>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.MembershipGroupRow = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="membership-group-row standard-row" group_id="', soy.$$escapeHtml(opt_data.group.id), '" ><div class="col col-color" style="color:', soy.$$escapeHtml(opt_data.group.color), '">&middot;</div><div class="col col-name">', soy.$$escapeHtml(opt_data.group.name), ' (', soy.$$escapeHtml(opt_data.group.code), ')<p class="labels">', (opt_data.group.is_global) ? '<span>Global</span>' : '', (opt_data.group.is_global && opt_data.group.vip) ? ', ' : '', (opt_data.group.vip) ? '<span>VIP</span>' : '', '</p></div><div class="col col-members"></div><div class="col col-action"><p class="button light action"><a href="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/membershipgroups/', soy.$$escapeHtml(opt_data.group.id), '/edit">Edit</a></p><p class="action"><a class="closer" href="">&times;</a></p><div class="local-popup delete-group-confirm no-display"><div class="float-end"></div><p class="question">Remove this group?</p><div class="btn-container"><p class="button danger"><a class="delete-confirm" href="javascript:void(0);">Confirm</a></p><p class="button plain"><a class="cancel-confirm" href="javascript:void(0);">nevermind</a></p></div></div></div></div>');
  return opt_sb ? '' : output.toString();
};
