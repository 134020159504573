function addBadge(response, badge_name, badge_text, unread) {
  var data = response.payload || response.data
  var id = data.request.id
  var row = $('#request-row-' + id)
  var badge = $('.col-message .status-badge', row)
  if (badge.length === 0) {
    var markup = '<span class="status-badge ' + badge_name + '">' + badge_text + '</span>'
    $('.col-message', row).prepend(markup)
  } else {
    badge.attr('class', '')
    badge.addClass('status-badge')
    badge.addClass(badge_name)
    badge.sext(badge_text)
  }
}

function updateMessage(response) {
  // get the latest conversation message from response data
  var data = response.payload || response.data
  var publicNotes = data.request.actual.public_note || data.request.actual.public_notes || ''
  var latest_message = ' ' + publicNotes
  if (latest_message) {
    var id = data.request.id
    var row = $('#request-row-' + id)
    var col = $('.col-message', row)
    var badge = $('.status-badge', col)
    col.empty()
    col.append(badge, latest_message)
  }
}

function updateRequestInitials(assigned) {
  var id = Assigned.entity_id
  var row = $('#request-row-' + id)
  var initials = $('.col-initials', row)
  if (assigned) {
    initials.sext(assigned.initials)
  } else {
    initials.sext('')
  }
}

// depends on Chatter, Interface, Attachments
var RequestSlideout = {
  base_url: null,
  booked_by_content: null,
  client_linked: false,

  init: function (
    venueId,
    base_url_venue,
    isdiningclass,
    is_basic_user,
    is_bank_setup,
    send_email_confirmation_by_default,
    send_sms_confirmation_by_default
  ) {
    this.base_url = base_url_venue + '/requests'
    this.base_url_venue = base_url_venue
    this.venueId = venueId
    this.is_basic_user = is_basic_user
    this.isdiningclass = isdiningclass
    this._is_bank_setup = is_bank_setup
    this.send_email_confirmation_by_default = send_email_confirmation_by_default
    this.send_sms_confirmation_by_default = send_sms_confirmation_by_default
    Chatter.init()
    this.followers = new Followers()
    this.followers.init(this.base_url_venue, $('#persons'), true)
    this.followers.kind = 'Request'
    Assigned.init(this.base_url_venue, updateRequestInitials)
    Attachments.init($('#base_url').val())
    this.bind()
  },

  submitNew: function (params, data, successHandler, errorHandler) {
    let url = `/api-yoa/reservation/${this.venueId}/book`
    if (params) {
      data = data + '&' + $.param(params)
    }
    if (!BillingService.isPaymentProviderSupported()) {
      data = data + '&override_payment_requirement=1'
    }
    $.ajax({
      method: 'post',
      url: url,
      data: data,
      success: function (response) {
        successHandler(response)
      },
      error: function (response) {
        errorHandler(response)
      },
    })
  },

  bind: function () {
    var that = this
    var showRespondMenuFn = function () {
      $('.respond-to-menu .container').addClass('show')
    }
    var hideRespondMenuFn = function () {
      $('.respond-to-menu .container').removeClass('show')
    }
    $('.respond-to').on('click', showRespondMenuFn)
    $('.respond-to').on('mouseleave', hideRespondMenuFn)

    var showTabFn = function (tab) {
      $('#' + tab + '-message').val('')
      $('#' + tab + '-internal-notes').val('')
      $('#main-interface').addClass('min-client')
      $('#tab-chat').hide()
      $('#tab-request').show()
      $('#tab-' + tab).show()
      that.updateTabHeight()
      $('#tab-' + tab).scrollTop(0)
    }

    $('#interface-navigation a').on('click', function () {
      if ($(this).attr('showtab') == 'profile-linker') {
        $('#client-profile-area').css('visibility', 'visible')
      } else {
        $('#client-profile-area').css('visibility', 'hidden')
      }
    })

    $('#profile-creator').on('click', function () {
      if (!that.profileValidate.validate()) {
        return
      }
      that.createNewClient()
    })

    $('#linker-info')
      .find('select,input')
      .on('change keyup', function () {
        var id_parts = this.id.split('-')
        id_parts.shift()
        var id = id_parts.join('-')
        var $el = $('#' + id)
        $el.val(this.value)
        $el.trigger('change')
        that.updateDisplays()
      })

    $('.book-request').on('click', function () {
      if (
        that.isdiningclass &&
        window.globalInit.venueSettings.internal_ar_booking_enabled &&
        !window.globalInit.featureFlags?.requestBookUseOldSlideOut
      ) {
        const venue = Pmp.Manager.Global.UserDomainVenues.venues.find(({ id }) => {
          return id == that.venueId
        })
        const shiftPersistentId = $('#res_shift').val()
        window.SvrManager.ActualSlideout.addReservation({
          venue,
          accountId: venue.account_id,
          date: moment(that._request.date_urlparam),
          partySize: that._request.max_guests,
          shiftPersistentId,
          clientId: that._request.venue_group_client?.id,
          reservationRequestId: that._request.id,
          typeId: that._request.experience_id,
        })
        window.SvrManager.ActualSlideout.registerCallbackHandlers({
          onBook: (actual, response) => {
            metric.track('Requests.book', { page: 'request-queue' })
            that.close()
            Requests.updateTallies()
            addBadge(response, 'booked', actual.status_formatted)
            updateMessage(response)
          },
        })
        return
      }

      // if it's the first in the duration list...
      if (that.isdiningclass && $('#select-duration').val() == '30') {
        var party_size = $('#the-real-party-size').val()
        var shift_persistent_id = $('#res_shift').val()
        shift = ReservationSlideOut._shiftDict[shift_persistent_id]
        var duration = shift.duration_minutes_by_party_size[party_size]
        $('#select-duration').val(duration)
      }
      ReservationSlideOut.HAS_SLIDEOUT_OPENED = true
      ReservationSlideOut._fetchAvailability()
      ReservationSlideOut._toggleReminderEmailDisplayForShift()
      showTabFn('book')
    })
    $('.accept-request').on('click', function () {
      showTabFn('accept')
    })
    $('.offer-request').on('click', function () {
      showTabFn('offer')
    })
    $('.trash-request').on('click', function () {
      showTabFn('trash')
    })
    $('.decline-request').on('click', function () {
      showTabFn('decline')
    })
    $('.close-tab').on('click', function () {
      that.closetab()
    })
    $('.mark-unread').on('click', function () {
      that.markUnread()
    })

    $('#offer-select-est-arrival-time').on('change', function () {
      $('.time-range-offer').sext($(this).val())
    })
    $('#accept-select-est-arrival-time').on('change', function () {
      var val = $(this).val() || 'Select time'
      $('.time-range-accept').sext(val)
    })

    var $sections = $('#tab-book .section-label')
    $sections.on('click', function (e) {
      $sections.removeClass('open')
      var $e = $(e.currentTarget)
      $e.addClass('open')
      var id = $e.prop('id')

      $set = $e.closest('#res-section-set')
      var fix = false
      if ($set.hasClass('no-bank')) {
        fix = true
      }
      $set.prop('class', id)
      if (fix) {
        $set.addClass('no-bank')
      }

      $('#tab-book').scrollTop(0)
    })

    $('#manager').on('click', '.deselect-client-profile-btn', function (e) {
      var isSource = $(e.target).parents('#source-client-profile-area').length > 0
      if (isSource) {
        return
      }
      var request_id = $('#main-interface input[name=request_id]').val()
      var url = that.base_url + '/' + request_id + '/save-edits'
      $.ajax({
        url: url,
        method: 'post',
        data: {
          remove_client: true,
        },
        success: function () {
          $('#linked-status').text('Profile matches available')
          $('#linked-name').text('')
          $('#interface-navigation').addClass('no_profile')
          $('#tab-profile,#tab-profile-linker').toggle()
          $('#input-venue-group-client-id').val('')
          that.updateDisplays()
          that.followers.refreshAndPersistFollowers()
        },
      })
    })

    $('#manager').on('click', '.select-client-profile-btn', function (e) {
      var $o = $(e.currentTarget)
      var isSource = $o.parents('#source-client-profile-area').length > 0
      if (isSource) {
        return
      }
      var venue_group_client_id = $.trim($o.attr('venue_group_client_id'))
      var request_id = $('#main-interface input[name=request_id]').val()
      that.updateVenueGroupClientInfo(venue_group_client_id, request_id, false)
    })

    $('#nav-profile').on('click', function (e) {
      var venue_id = $('#input-venue-id').val()
      var venue_group_client_id = $('#input-venue-group-client-id').val()
      if (venue_group_client_id) {
        that.showProfile(venue_id, venue_group_client_id)
      }
    })

    $('#interface-client h3 span.toggle').on('click', function () {
      $('#main-interface').toggleClass('min-client')
      that.updateTabHeight()
    })

    $('#confirm-book').on('click', function (event) {
      var button_el = $('#confirm-book')
      if (button_el.hasClass('disabled')) {
        return
      }

      if (!ReservationSlideOut.validator.validate()) {
        return
      }

      button_el.addClass('disabled')

      $('#client-profile-area').hide()
      var deferred = $.Deferred()
      var processPayments = ReservationSlideOut.shouldProcessPayment() && BillingService.isPaymentProviderSupported()
      if (processPayments) {
        $form = $('#book-res')
        var name = $form.find('input[name="cardholder_name"]').val()
        if (ReservationSlideOut._isStripe) {
          var advanced_payment = !$('#save_for_later').is(':checked')
          if (advanced_payment) {
            var amount = $('#payment_display').val()
            deferred = BillingService.getPaymentIntent(ReservationSlideOut._stripe, ReservationSlideOut.stripeMount, name, amount)
          } else {
            deferred = BillingService.getSetupIntent(ReservationSlideOut._stripe, ReservationSlideOut.stripeMount, name)
          }
        } else {
          var number = $form.find('input[name="card_number"]').val()
          var exp_month = $form.find('select[name="cc_expiration_month"]').val()
          var exp_year = $form.find('select[name="cc_exp_year"]').val()
          var cvc = $form.find('input[name="card_cvv"]').val()
          deferred = BillingService.getToken(name, number, exp_month, exp_year, cvc)
        }
      }

      deferred
        .done(function (card_token) {
          let formInputSelectors = '#book-res input:not(.disabled), #book-res select, #book-res textarea, #add-details'
          let data = $(formInputSelectors)
            .filter((_, element) => $(element).attr('name') !== 'tag_full_hash')
            .serialize()
          data =
            data +
            '&' +
            $('.reservation-codes [name=tag_full_hash]')
              .map(function (index, element) {
                element['name'] = 'reservation_tag_full_hash'
                return element
              })
              .serialize()

          data =
            data +
            '&' +
            $('.client-codes [name=tag_full_hash]')
              .map(function (index, element) {
                element['name'] = 'client_tag_full_hash'
                return element
              })
              .serialize()
          data =
            data +
            '&' +
            $('.source-codes [name=tag_full_hash]')
              .map(function (index, element) {
                element['name'] = 'source_tag_full_hash'
                return element
              })
              .serialize()

          if (card_token) {
            data = data + '&card_token=' + card_token
          }
          let successHandler = function (response) {
            that.handlebook(response)
            CustomerSuccessTracker.trackRequestInbox('Book Now from Request')
            button_el.removeClass('disabled')
          }
          let errorHandler = function (response) {
            var responseData = $.parseJSON(response.responseText)
            var responseError = responseData.payload || responseData.data
            if (responseError && responseError.hasOwnProperty('overlapping_res_ids')) {
              const overlappingResIds = responseError.overlapping_res_ids
              const book_and_cancel_params = {
                override_existing_booking: true,
                overlapping_res_ids: JSON.stringify(overlappingResIds),
              }
              const book_params = {
                override_existing_booking: true,
              }
              let modalSuccessHandler = function (response, unmount) {
                console.log(response)
                unmount()
                that.handlebook(response)
                $('#warning-modal').hide()
              }
              let modalErrorHandler = function (response) {
                let responseData = $.parseJSON(response.responseText)
                let msg = ''
                if (responseData.errors) {
                  msg = responseData.errors.join(' ')
                } else if (responseData.msg) {
                  msg = responseData.msg
                }
                Interface._alert(msg)
              }
              const book_and_cancel_handler = unmount => {
                RequestSlideout.submitNew(
                  book_and_cancel_params,
                  data,
                  response => modalSuccessHandler(response, unmount),
                  response => modalErrorHandler(response)
                )
              }
              const book_handler = unmount => {
                RequestSlideout.submitNew(
                  book_params,
                  data,
                  response => modalSuccessHandler(response, unmount),
                  response => modalErrorHandler(response)
                )
              }
              $('#warning-modal').show()
              window.SvrManager.Components.mountWarningModal(
                'warning-modal',
                'This client has another booking during this time.',
                responseError.overlapping_res_array,
                responseError.can_cancel
                  ? `Book and Cancel Other Booking${responseError.overlapping_res_array.length > 1 ? 's' : ''}`
                  : null,
                'Book Anyway',
                responseError.can_cancel
                  ? unmount => {
                      book_and_cancel_handler(unmount)
                    }
                  : null,
                unmount => {
                  book_handler(unmount)
                },
                unmount => {
                  unmount()
                  $('#warning-modal').hide()
                }
              )
            } else {
              let msg = ''
              if (responseData.errors) {
                msg = responseData.errors.join(' ')
              } else if (responseData.msg) {
                msg = responseData.msg
              }
              Interface._alert(msg)
            }
            button_el.removeClass('disabled')
          }
          that.submitNew(null, data, successHandler, errorHandler)
        })
        .fail(function (error) {
          console.log('Error with storing/charging card', error)

          Interface._alert('Please check your card information.')
          button_el.removeClass('disabled')
        })

      if (!processPayments) {
        deferred.resolve()
      }
    })

    $('#main-interface form.request-form .submitter').on('click', function (event) {
      var form = $(event.target).closest('form')
      var request_id = form.find('[name=request_id]').val()
      var action = form.find('[name=action_type]').val()

      var url = that.base_url + '/' + request_id + '/' + action

      form.prop('action', url)

      var handler = that['handle' + action],
        error = function () {
          Interface._alert('Could not ' + action + '.  Please refresh the page.')
        }

      var is_valid = true
      if (action == 'offer' || action == 'accept') {
        is_valid = that.validateoffer()
      }

      if (is_valid) {
        Interface.sendform(event, handler, error)
      }
    })

    CostOptions.init('#tab-offer')
    CostOptions.init('#book-res')

    var validatorCallback = function (field, defaultValidatorFn, isValid) {
      if (isValid) {
        return true
      }

      // essentially, ignore callback when it is not a concierge request
      if (!$('#main-interface').hasClass('is_concierge')) {
        return isValid
      }

      // if it's a concierge request, only enforce phone numbers if it's a dining request
      var field_id = field.attr('id')
      if (field_id == 'input-phone') {
        if (that.isdiningclass) {
          return isValid
        } else {
          return true
        }
      }

      if (field_id == 'input-email') {
        return true
      }

      return isValid
    }

    this.validator = new sr.Validator($('#tab-accept, #tab-offer, #tab-decline, #tab-trash, #tab-chat'), undefined)
    ReservationSlideOut.validator = new sr.Validator(
      $('#main-interface #tab-book'),
      ReservationSlideOut._currency_symbol,
      validatorCallback
    )
    ReservationSlideOut.setupValidator()

    // Can prolly combine this with existing validator
    this.profileValidate = new sr.Validator($('#linker-info'), undefined)
  },

  getNameFromForm: function () {
    var names = [$('#input-firstname').val(), $('#input-lastname').val()]
    var joiner = ' '

    names = $.grep(names, function (n) {
      return $.trim(n) != ''
    })

    if (Pmp.Manager.Global._last_name_first) {
      names.reverse()
      joiner = ', '
    }

    return names.join(joiner)
  },

  // Unfortunately, maintaining consistency is not possible
  // in this with just populateClientDisplayAreas. Keeping
  // original request data will be a pain point even if this
  // is all turning to react. Be aware if you're refactoring.
  updateDisplays: function (request_id, profileData) {
    var phone = $('#input-phone').val()
    var name =
      (profileData && profileData.venue_group_client && profileData.venue_group_client.name_last_first_display) || this.getNameFromForm()

    $('#interface-client .-client_display_name').sext(name)
    if (request_id) {
      $('#request-row-' + request_id + ' .col-client .name').sext(name)
    }
    $('#interface-client .-phone_number_formatted').text(phone)
    $('#nav-profile span').sext('Profile: ' + name)
  },

  updateVenueGroupClientInfo: function (venueGroupClientId, requestId, suppress) {
    var that = this
    var callback = function () {
      that.selectVenueGroupClientId(venueGroupClientId)
    }
    var onLoadCallback = function (profileData) {
      if ($('#linker-info').length) {
        that.updateDisplays(requestId, profileData)
      }
    }
    ReservationSlideOut.selectVenueGroupClientId(venueGroupClientId, suppress, callback, onLoadCallback)
  },

  // Called from res slideout func of same name
  selectVenueGroupClientId: function (vgc_id, suppress) {
    var request_id = $('#main-interface input[name=request_id]').val()
    var url = this.base_url + '/' + request_id + '/save-edits'
    var that = this

    $.ajax({
      url: url,
      method: 'post',
      data: {
        venue_group_client_id: vgc_id,
      },
      success: function () {
        $('#interface-navigation').removeClass('no_profile')

        that.followers.refreshAndPersistFollowers()

        if (suppress) {
          return
        }
      },
    })
  },

  createNewClient: function () {
    var request_id = $('#main-interface input[name=request_id]').val()
    var url = this.base_url + '/' + request_id + '/new_client'
    var data = $('#linker-info form').serialize()

    var that = this
    $.ajax({
      url: url,
      method: 'post',
      data: data,
      success: function (response) {
        that.updateVenueGroupClientInfo(response.payload.venue_group_client.id, request_id, false)
      },
    })
  },

  updateTabHeight: function (is_new) {
    var height = $('#interface-status').height()
    if (!is_new) {
      height += $('#interface-client').height() + 61
    }
    $('#main-interface')
      .children('.tab')
      .css({ top: height + 'px' })
    $('#tab-request').children('.tab').css({ top: '0px' })
  },

  showProfile: function (venue_id, venue_group_client_id) {
    $.ajax({
      url: '/manager/' + venue_id + '/clients/profile/' + venue_group_client_id + '/actuals',
      dataType: 'json',
      success: function (response) {
        var cancels = 0
        var no_shows = 0

        $('#reservation-history').empty()
        for (var i in response.payload.content.actual_dicts) {
          actual = response.payload.content.actual_dicts[i].actual
          if (actual.status == 'NO_SHOW') {
            ++no_shows
          } else if (actual.status == 'CANCELED') {
            ++cancels
          }
          var p = $('<p/>').prop('class', 'history-item')
          var em = $('<em/>').sext(actual.date_formatted_short)
          var span_name = $('<span/>').prop('class', 'name').sext(actual.venue_name)
          var span_via = $('<span/>').prop('class', 'via').sext(actual.via)
          var span_min = $('<span/>').prop('class', 'min').sext(actual.min_price_formatted)
          var span_spend = $('<span/>').prop('class', 'spend').sext(actual.final_bill_formatted_no_decimals)
          var span_note = $('<span/>').prop('class', 'note').sext(actual.notes)
          p.append(em, span_name, span_via, span_min, span_spend, span_note)
          $('#reservation-history').append(p)
        }

        var no_shows_plural = no_shows > 1 ? 's' : ''
        var cancels_plural = cancels > 1 ? 's' : ''
        $('#no-shows').html('<em>' + no_shows + '</em> no show' + no_shows_plural)
        $('#cancels').html('<em>' + cancels + '</em> cancel' + cancels_plural)
      },
    })
  },

  loadrequest: function (request_id, successHandler) {
    var that = this
    var url = that.base_url + '/' + request_id

    Chatter.num_messages = 0
    that._current_request_id = request_id
    that._request = undefined

    $.ajax({
      url: url,
      method: 'get',
      success: function (response) {
        // only render last clicked request
        if (response.payload.request.id != that._current_request_id) {
          return
        }
        that.renderinterface(response)
        that._request = response.payload.request
        if (successHandler) {
          successHandler(response.payload.request)
        }
      },
      error: function (response) {
        Interface._alert('Unable to load request. Please try again later.')
      },
    })
  },

  renderinterface: function (data, _offset) {
    var that = this
    that.clear()
    that.closetab()
    $('#input-reservation-request-id').val(data.payload.request.id)

    if (data.payload.request.is_concierge_request) {
      $('#main-interface').addClass('is_concierge')
      $('.info.via').show()
      $('.info.first-info.email').hide()
      $('.info.first-info.phone-display').hide()
    } else {
      $('.info.via').hide()
      // if not concierge request, respect whatever css sets
      $('.info.first-info.email').css('display', '')
      $('.info.first-info.phone-display').css('display', '')
      $('#main-interface').removeClass('is_concierge')
    }

    if (data.payload.request.is_internal) {
      $('div.respond-to .respond-to-menu p.row.offer-request').hide()
    } else {
      $('div.respond-to .respond-to-menu p.row.offer-request').show()
    }

    // Set the status title on the slideout by setting a class on the entire
    if (data.payload.request.request_status == 'REMOVED') {
      $('#main-interface').addClass('trashed')
    } else if (data.payload.request.request_status === 'DECLINED') {
      $('#main-interface').addClass('declined')
    } else if (data.payload.request.actual && data.payload.request.actual.status == 'CONFIRMED') {
      $('#main-interface').addClass('confirmed')
    } else if (data.payload.request.request_status == 'APPROVED' && data.payload.request.is_offer_pending == false) {
      $('#main-interface').addClass('accepted')
    } else if (data.payload.request.request_status == 'APPROVED' && data.payload.request.actual_id) {
      $('#main-interface').addClass('booked')
    } else if (data.payload.request.request_status == 'FULFILLED') {
      $('#main-interface').addClass('accepted')
    } else if (data.payload.request.is_expired) {
      $('#main-interface').addClass('expired')
    } else if (data.payload.request.is_offer_pending) {
      $('#main-interface').addClass('hold')
    } else if (data.payload.request.is_priority_alert) {
      $('#main-interface').addClass(data.payload.request.request_status.toLowerCase())
      $('#main-interface').addClass('priority-alert')
    } else {
      $('#main-interface').addClass(data.payload.request.request_status.toLowerCase())
    }

    Interface.sploosh(data.payload.request, '#main-interface')
    Interface.sploosh(data.payload.request.venue_group_client, '#tab-profile')
    CostOptions.update('#tab-offer', data.payload.request)

    var time_display = $('.time-range-offer')
    if (!time_display.sext()) {
      time_display.sext('Set time')
    } else {
      $('#offer-select-est-arrival-time').val(time_display.sext())
      $('#accept-select-est-arrival-time').val(time_display.sext())
    }

    if (this.is_basic_user) {
      $('#chat-interface a#chat-new-message span.auto, #chat-send-message span.msg span.auto').sext('manager')
      this._hideAddNote()
    } else {
      this._showAddNote()
    }

    if (data.payload.request.is_messaging_disabled) {
      $('#chat-new-message').hide()
      $('#chat-interface > p.message-links > span.divider').hide()
    } else {
      $('#chat-new-message').show()
      $('#chat-interface > p.message-links > span.divider').show()
    }

    this._fillreservationform(data.payload.request)

    // Note that setDate must come after option config otherwise it resets the date!
    var date = $.datepicker.parseDate('mm-dd-yy', data.payload.request.date_urlparam)
    $('#req-date-selector').datepicker('option', 'closeText', 'Cancel')
    $('#req-date-selector').datepicker('option', 'showButtonPanel', true)
    $('#req-date-selector').datepicker('setDate', date)

    if (data.payload.request.venue_group_client) {
      $('#input-venue-group-client-id').val(data.payload.request.venue_group_client.id)
      $('.client-profile-link').attr('href', that.base_url_venue + '/clients/profile/' + data.payload.request.venue_group_client.id)
    } else {
      $('#input-venue-group-client-id').val('')
      $('.client-profile-link').attr('href', '')
    }

    if (!$('.cost-type .form-element.checked').length) {
      $('#id_dollar').trigger('click')
    }

    // TODO cleaner
    $('#main-interface p.notes').show()
    if (!$('#main-interface p.notes').sext()) {
      $('#main-interface p.notes').hide()
    }

    $('#chat-entity_id').val(data.payload.request.id)

    var $accept_time_options = $('#accept-select-est-arrival-time')
    $accept_time_options.find('option').remove()
    var time_options = data.payload.request.time_range_options

    // Eye of newt
    ReservationSlideOut._select_time(time_options[0])
    ReservationSlideOut._loadShifts(data.payload.request.date_urlparam)

    // Need this for validation to work
    var emptyOption = $('<option />').val('').sext('').prop('selected', true)
    $accept_time_options.append(emptyOption)

    for (var o = 0; o < time_options.length; o++) {
      var option = $('<option />').val(time_options[o]).sext(time_options[o])
      if (data.payload.request.arrival_time_display == time_options[0]) {
        option.attr('selected', 'selected')
      }
      $accept_time_options.append(option)
    }

    if (!time_options.length) {
      $accept_time_options.html($('#emergency-default').html())
      $accept_time_options.trigger('change')
    }

    var hold_select = $('[name=hold_duration]')
    hold_select.find('option').remove()
    hold_select.append($('<option/>').val(0).sext('No expiration'))
    var hold_duration_options = data.payload.request.hold_duration_options
    for (var i = 0; i < hold_duration_options.length; ++i) {
      var option = $('<option/>').val(hold_duration_options[i].seconds).sext(hold_duration_options[i].display)
      hold_select.each(function () {
        $(this).append(option.prop('outerHTML'))
      })
    }

    // select the option
    if (data.payload.request.arrival_time_display) {
      if ($('#acce-tselect-est-arrival-time').is(':visible')) {
        $("#accept-select-est-arrival-time option[value='" + data.payload.request.arrival_time_display + "']").prop('selected', true)
      } else {
        $("#select-est-arrival-time option[value='" + data.payload.request.arrival_time_display + "']").prop('selected', true)
      }
    }

    // indicate a venue group client match
    if (data.payload.request.venue_group_client) {
      this.client_linked = true
      $('#interface-navigation').removeClass('no_profile')
      this.updateVenueGroupClientInfo(data.payload.request.venue_group_client.id, data.payload.request.id, true)
    } else {
      this.client_linked = false
      $('#interface-navigation').addClass('no_profile')
      $('.groups-prepend.client').empty()
      ReservationSlideOut._doClientProfileSearch(true)
    }

    Interface.openslide('#main-interface')

    this.followers.entity_id = data.payload.request.id
    this.followers.kind = 'Request'
    this.followers.refresh(data.payload.request.followers, that.booked_by_content)

    Assigned.entity_id = data.payload.request.id
    Assigned.kind = 'Request'
    Assigned.refresh(data.payload.request.assigned, that.booked_by_content)

    // do a preliminary fetch
    Chatter.fetch()
    Attachments.initWithContext(data.payload.MEDIA_URL, data.payload.request.concierge_or_client_name)
    Attachments.fetch()

    this.updateTabHeight()
  },

  _handleLoadClientProfileMatches: function (matches_available) {
    if (matches_available) {
      $('#linked-status').text('Profile matches available')
    } else {
      $('#linked-status').text('No profile matches')
    }
  },

  _hideAddNote: function () {
    $('#chat-interface').find('span.divider, a#chat-new-note').hide()
  },

  _showAddNote: function () {
    $('#chat-interface').find('span.divider, a#chat-new-note').show()
  },

  _formatDate: function () {
    // Nice formatting.
    // NOTE: is alternative to using datepicker('option', 'dateFormat')
    // b/c it only accepts current year if the format doesn't have a year
    var dateSel = $('#res-date-selector')
    if (!dateSel || !dateSel.length) {
      return
    }
    var dateObj = $(dateSel).datepicker('getDate')
    if (dateObj) {
      $(dateSel).val($.datepicker.formatDate('D M, d', dateObj))
    }
  },

  _fillreservationform: function (request) {
    var date = $.datepicker.parseDate('mm-dd-yy', request.date_urlparam)
    $('#book-res #res-date-selector').datepicker('setDate', date)
    this._formatDate()

    ReservationSlideOut._updateDateButtons(request.date_urlparam)
    ReservationSlideOut._updatePartyInterface(request.max_guests)

    $('#book-res #input-email').val(request.email)
    $('#link-input-email').val(request.email)

    // This has to be by name as the ids are repeated.
    $('#main-interface select[name=res_type]').val(request.request_class)

    $('#book-res #input-firstname').val(request.first_name)
    $('#book-res #input-lastname').val(request.last_name)
    $('#book-res #select-phone-locale').val(request.phone_number_locale)

    $('#link-select-phone-locale').val(request.phone_number_locale)
    $('#book-res #party-size-override').val(request.max_guests)

    $('#book-res #id_mf_ratio_male').val(request.mf_ratio_male)
    $('#book-res #id_mf_ratio_female').val(request.mf_ratio_female)

    if (request.gender == 'MALE' || request.gender == 'FEMALE') {
      $('#add-details #id_' + request.gender).trigger('click')
    }

    $('#book-res #birthday-day').val(request.birthday_day)
    $('#book-res #birthday-month').val(request.birthday_month)

    if (request.is_concierge_request) {
      $('#book-res #booked-by-interface').hide()
      $('#book-res #booked-by').val('')
      $('#book-res #booked-by-interface-3p').show()
      $('#book-res #booked-by-3p').addClass('disabled').val(request.concierge_or_client_name)
      $('#book-res #is_concierge').val(true)
      $('#input-email').val('')
      $('.form-element.text.email').hide()
      $('.form-element.checkbox.send_client_email').hide()
      $('#send_client_sms_row').hide()
      $('#id_send_client_email').prop('checked', false)
      $('#id_send_client_sms').prop('checked', false)

      if (!this.isdiningclass) {
        $('#input-phone').val('')
        $('.form-element.text.phone_number').hide()
        $('#id_select-phone-locale').hide()
      }
      $('.form-element.public-notes').show()
    } else {
      $('#book-res #booked-by-interface').show()
      $('#book-res #booked-by-interface-3p').hide()
      $('#book-res #booked-by-3p').val('')
      var $booked_by_select = $('#book-res #booked-by')
      if (request.booked_by_user_key) {
        $booked_by_select.val(request.booked_by_user_key)
      } else if (request.is_app) {
        if ($booked_by_select.find('option[value="' + request.user_id + '"]').length == 0) {
          // if the booked by name is not in the list, add it to the options (could have been deleted)
          var option = $('<option />').val(request.user_id).sext(request.source)
          $booked_by_select.append(option)
        }
        $booked_by_select.val(request.user_id)
      } else {
        $booked_by_select.val(Pmp.Manager.Global._user_id)
      }
      $('#book-res #is_concierge').val('')
      $('.form-element.text.email').show()
      $('.form-element.checkbox.send_client_email').show()
      if (
        ($('#id_send_client_email').prop('checked') && !this.send_email_confirmation_by_default) ||
        (!$('#id_send_client_email').prop('checked') && this.send_email_confirmation_by_default)
      ) {
        $('#id_send_client_email').click()
      }
      if (
        ($('#id_send_client_sms').prop('checked') && !this.send_sms_confirmation_by_default) ||
        (!$('#id_send_client_sms').prop('checked') && this.send_sms_confirmation_by_default)
      ) {
        $('#id_send_client_sms').click()
      }
      $('.form-element.text.phone_number').show()
      $('#id_select-phone-locale').show()
      if ($('#id_send_client_email').prop('checked')) {
        $('.form-element.public-notes').show()
      } else {
        $('.form-element.public-notes').hide()
      }
    }

    CostOptions.update('#book-res', request)

    $('#book-res #venue-private-notes-textarea').val('')

    // show or hide payments pane
    if (this._is_bank_setup && BillingService.isPaymentProviderSupported()) {
      $('#res-section-set').removeClass('no-bank')
    } else {
      $('#res-section-set').addClass('no-bank')
    }

    // Reset client profile form
    this.resetClientProfileForm()

    ReservationSlideOut.LOAD_LOCK = false
  },

  resetClientProfileForm: function () {
    $('#profile-salutation-select').val('')
    $('#input-phone-alt').val('')
    $('.email-input-alt').val('')
    $('input[name=gender]').prop('checked', false)
    $('input[name=gender]').closest('.form-element').removeClass('checked')
    $('#profile-one-liner').val('')
    $('#profile-company').val('')
    $('#profile-title').val('')
    $('#birthday-month').val('')
    $('#birthday-day').val('')
    $('#anniversary-month').val('')
    $('#anniversary-day').val('')
    $('.client-codes #find-tags-input span').empty()
  },

  _DIGITS_REGEX: /^[0-9]+$/,

  validateoffer: function () {
    return this.validator.validate()
  },

  handleaccept: function (data) {
    metric.track('Requests.accept', { page: 'request-queue' })
    if (data.payload.request.reserve_immediately) {
      $('#main-interface').addClass('accepted')
    } else {
      $('#main-interface').addClass('hold')
    }
    addBadge(data, 'hold', 'hold')
    RequestSlideout.updateTabHeight()
    RequestSlideout.loadrequest(data.payload.request.id)
    Requests.updateTallies()
  },

  handledecline: function (data) {
    metric.track('Requests.decline', { page: 'request-queue' })

    addBadge(data, 'declined', 'declined')
    $('#main-interface').addClass('declined')
    RequestSlideout.updateTabHeight()
    RequestSlideout.loadrequest(data.payload.request.id)
    Requests.updateTallies()
  },

  handleoffer: function (data) {
    var self = this
    metric.track('Requests.offer', { page: 'request-queue' })

    Interface.sploosh(data.payload.request, '#main-interface')
    $('#main-interface').addClass('offermade')
    addBadge(data, 'hold', 'hold')
    RequestSlideout.updateTabHeight()
    RequestSlideout.loadrequest(data.payload.request.id)
    Requests.updateTallies()
  },

  handletrash: function (data) {
    metric.track('Requests.trash', { page: 'request-queue' })
    addBadge(data, 'removed', 'removed')
    $('#main-interface').addClass('trashed')
    RequestSlideout.updateTabHeight()
    RequestSlideout.loadrequest(data.payload.request.id)
    Requests.updateTallies()
  },

  handlebook: function (response) {
    metric.track('Requests.book', { page: 'request-queue' })

    addBadge(response, 'booked', response.data.request.actual.status_formatted)
    updateMessage(response)
    $('#main-interface').addClass('accepted')

    if (response.data.charge_status === 'FAILED') {
      Interface._alert('Reservation was booked, but charge failed!')
    }

    RequestSlideout.updateTabHeight()
    RequestSlideout.loadrequest(response.data.request.id)
    Requests.updateTallies()
  },

  markUnread: function () {
    var request_id = $('#main-interface').find('input[name=request_id]').val()
    var url = this.base_url + '/' + request_id + '/unread'
    var that = this
    $.ajax({
      url: url,
      method: 'post',
      success: function (data) {
        that.handleunread(data)
      },
      error: function () {
        Interface._alert('Could not mark as unread. Please try again later.')
      },
    })
  },

  handleunread: function (data) {
    addBadge(data, 'new', 'new', true)
    $('#request-row-' + data.payload.request.id).removeClass('read selected')
    this.close()
  },

  clear: function () {
    Chatter.clearmessage()
    var target = $('#main-interface')
    Interface.clear('#main-interface')
    target.removeClass('not_reconciled no_show no_entry arrived arrived_partial canceled complete confirmed priority-alert')
    target
      .removeClass('new-reservation new-request edit-reservation table bar editing accepted hold declined offermade trashed expired')
      .addClass('table')
    this.closetab()
    $('#select-seating-area').val(ReservationSlideOut._default_seating_area)
    $('#select-seating-area').trigger('change')
    $('#linker-status').text('Profile matches')
    $('#tab-profile').hide()
    CostOptions.clear()
  },

  close: function () {
    this.clear()
    Interface.closeslide('#main-interface')
    return false
  },

  closetab: function () {
    $('#main-interface').removeClass('min-client')
    $('#tab-request .tab').hide()
    $('#tab-request').hide()
    $('#tab-chat').show()
    this.updateTabHeight()
  },
}
