// This file was automatically generated from manager.manage.blackoutdates.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }


Nightloop.Templates.Manager.Manage.BlackoutDates = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.BlackoutDatesBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.BlackoutDatesBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'blackout_dates'}), output);
  output.append('<div id="page-blackoutdates" class="has-sidebar"><div id="main-area"><div id="content-header"><h2>BLACKOUT DATES</h2></div>', (opt_data.content.msg) ? '<div class="global-message success fade">' + soy.$$escapeHtml(opt_data.content.msg) + '</div>' : '', '<div class="float-left"><div class="col-row " id="blackout-description" style="font-size: 14px">Adding a Blackout to a date restricts all external reservations for the entire day. Blackout dates do not restrict internal users from booking. For more information click&nbsp;<a href="https://help.sevenrooms.com/hc/en-us/articles/360005132751-Adding-and-Removing-Blackout-Dates">here</a>.</div><div class="row header"><div class="col date">DATE</div><div class="col day">DAY</div><div class="col description">DESCRIPTION</div><div class="col blackout-type">BLACKOUT</div><div class="col delete">&nbsp;</div><div class="float-end"></div></div><form id=\'blackout-dates-form\' class=\'styled\' method=\'post\' action=\'', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/blackoutdates\'><input type=\'hidden\' name=\'post\' value=\'post\' /><div id=\'no-dates-listed\' class=\'col-row ', (opt_data.content.blackout_dates.length > 0) ? 'no-display' : '', '\'>You have no blackout dates listed. Click \'Add New\' to begin adding blackout dates.</div><div class="bd-list">');
  var dateList14340 = opt_data.content.blackout_dates;
  var dateListLen14340 = dateList14340.length;
  for (var dateIndex14340 = 0; dateIndex14340 < dateListLen14340; dateIndex14340++) {
    var dateData14340 = dateList14340[dateIndex14340];
    output.append('<div class="row data"><div class="col date">', soy.$$escapeHtml(dateData14340.date_formatted), '</div><div class="col day">', soy.$$escapeHtml(dateData14340.day_formatted), '</div><div class="col description">', soy.$$escapeHtml(dateData14340.description), '</div><div class="col blackout-type">', soy.$$escapeHtml(dateData14340.blackout_types_display), '</div><div class="col delete"><a class="closer delete-blackout-link no-display" href="javascript:void(0);" onclick=\'javascript:Pmp.Manager.Manage.BlackoutDates.removeBlackoutDate(this);\'>&times</a></div><input class=\'date-formatted\' type="hidden" name="date" value=\'', soy.$$escapeHtml(dateData14340.date_full_formatted), '\' /><input type="hidden" name="description" value=\'', soy.$$escapeHtml(dateData14340.description), '\' /><input type="hidden" name="index" value=\'', soy.$$escapeHtml(dateIndex14340), '\' />', (dateData14340.is_reservation) ? '<input type="hidden" name="is_reservation_' + soy.$$escapeHtml(dateIndex14340) + '" value=\'1\' />' : '', (dateData14340.is_guestlist) ? '<input type="hidden" name="is_guestlist_' + soy.$$escapeHtml(dateIndex14340) + '" value=\'1\' />' : '', '<div class="float-end"></div></div>');
  }
  output.append('</div></form><a id="add-new-blackout-date" href="javascript:void(0)">Add new</a><div class="float-end"></div><div id=\'error\'>&nbsp;</div><p class="button"><a id="submit-blackout-dates" href="">Save changes</a></p></div><div id="upcoming" class="no-display"><div class="header">UPCOMING HOLIDAYS</div>');
  var holidayList14369 = opt_data.content.holidays;
  var holidayListLen14369 = holidayList14369.length;
  for (var holidayIndex14369 = 0; holidayIndex14369 < holidayListLen14369; holidayIndex14369++) {
    var holidayData14369 = holidayList14369[holidayIndex14369];
    output.append('<div class="row"><div class="date">', soy.$$escapeHtml(holidayData14369.date), '</div><div class="float-right">', soy.$$escapeHtml(holidayData14369.description), (holidayData14369.closed) ? ' <img src=\'' + soy.$$escapeHtml(opt_data.MEDIA_URL) + 'images/NO-icon.png\' />' : '', '</div><div class="float-end"></div></div>');
  }
  output.append('<div id="more" class="no-display"><a href="javascript:void(0)">more...</a></div></div><div class="float-end"></div></div><script type="text/javascript">$(document).ready( function() {Pmp.Manager.Manage.BlackoutDates.initialize("', soy.$$escapeHtml(opt_data.venue.locale), '", ', soy.$$escapeHtml(opt_data.content.blackout_dates.length), ');})<\/script></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.BlackoutDatesEditRow = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class=\'row data\'><input type="hidden" name="index" value="', soy.$$escapeHtml(opt_data.count), '" /><div class=\'col date\'><input id=\'dateDisplay-', soy.$$escapeHtml(opt_data.count), '\' class=\'datepicker date-input date-formatted\' name=\'date-localized\' type=\'text\' onchange=\'javascript:Pmp.Manager.Manage.BlackoutDates.onChangeDateInput(this);\'/><input id=\'dateSubmit-', soy.$$escapeHtml(opt_data.count), '\' name=\'date\' type=\'hidden\' /></div><div class=\'col day\'><span>&nbsp;</span></div><div class=\'col description\'><input class=\'desc-input\' name=\'description\' type=\'text\' maxLength=28 /></div><div class=\'col blackout-type\'>');
  Nightloop.Base.FormElement({id: 'id_res-' + soy.$$escapeHtml(opt_data.count), parentclass: 'is_reservation', label: 'Reservations', name: 'is_reservation_' + soy.$$escapeHtml(opt_data.count), type: 'checkbox'}, output);
  Nightloop.Base.FormElement({id: 'id_gl-' + soy.$$escapeHtml(opt_data.count), label: 'Guestlist', name: 'is_guestlist_' + soy.$$escapeHtml(opt_data.count), type: 'checkbox'}, output);
  output.append('</div><div class=\'col delete\'><a class=\'closer delete-blackout-link no-display\' href=\'javascript:void(0);\' onclick=\'javascript:Pmp.Manager.Manage.BlackoutDates.removeBlackoutDate(this);\'>&times</a></div><div class=\'float-end\'></div></div>');
  return opt_sb ? '' : output.toString();
};
