// This file was automatically generated from manager.actual.partialedit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Actual == 'undefined') { Nightloop.Templates.Manager.Actual = {}; }


Nightloop.Templates.Manager.Actual.PartialEditPopup = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Main.Popup.PopupLayout(soy.$$augmentData(opt_data, {div_id: 'manager-edit-partial-res-popup', header_html: 'Edit Reservation', show_close_link: 'false', DynamicTemplate: 'Nightloop.Templates.Manager.Actual.PartialEditPopupBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Actual.PartialEditPopupBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<form id="edit-partial-res-form" method="POST" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/actual/', soy.$$escapeHtml(opt_data.content.actual.id), '/partial-edit"><div id="actual-details-display" class="body-block"><div class="block-title">', soy.$$escapeHtml(opt_data.content.actual.client_display_name), '</div><div class="editable-area"><div><div class="float-left w35 right indent-right">Reservation No.</div><div class="float-left w60 number">', soy.$$escapeHtml(opt_data.content.actual.reference_code), '</div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Date:</div><div class="float-left w60">', soy.$$escapeHtml(opt_data.content.actual.date_formatted_long_display), '</div><div class="float-end"></div></div>');
  if (opt_data.content.can_pick_seating) {
    if (opt_data.content.actual.system_class == 'BAR') {
      output.append('<div><div class="float-left w35 right indent-right">Table No.:</div><div class="float-left w60">Bar</div><div class="float-end"></div></div>');
    } else {
      output.append('<div><div class="float-left w35 right indent-right">Seating Area:</div><div class="float-left w60"><select id="select-seating-area" name="venue_seating_area_id">');
      if (opt_data.content.actual.is_reservation) {
        var seating_areaList6953 = opt_data.content.venue_seating_areas;
        var seating_areaListLen6953 = seating_areaList6953.length;
        for (var seating_areaIndex6953 = 0; seating_areaIndex6953 < seating_areaListLen6953; seating_areaIndex6953++) {
          var seating_areaData6953 = seating_areaList6953[seating_areaIndex6953];
          output.append((opt_data.content.actual.venue_seating_area_id == seating_areaData6953.id) ? '<option value="' + soy.$$escapeHtml(seating_areaData6953.id) + '" selected="selected" >' + soy.$$escapeHtml(seating_areaData6953.code) + '</option>' : '');
        }
      } else {
        output.append('<option value=""></option>');
        var seating_areaList6964 = opt_data.content.venue_seating_areas;
        var seating_areaListLen6964 = seating_areaList6964.length;
        for (var seating_areaIndex6964 = 0; seating_areaIndex6964 < seating_areaListLen6964; seating_areaIndex6964++) {
          var seating_areaData6964 = seating_areaList6964[seating_areaIndex6964];
          output.append('<option value="', soy.$$escapeHtml(seating_areaData6964.id), '"', (opt_data.content.actual.venue_seating_area_id == seating_areaData6964.id) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(seating_areaData6964.code), '</option>');
        }
      }
      output.append('</select></div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Table No.:</div><div class="float-left w60"><select id="select-seating-table" name="table_ids"><option value=""></option>');
      var tableList6976 = opt_data.content.all_table_inventory_items;
      var tableListLen6976 = tableList6976.length;
      for (var tableIndex6976 = 0; tableIndex6976 < tableListLen6976; tableIndex6976++) {
        var tableData6976 = tableList6976[tableIndex6976];
        output.append('<option value="', soy.$$escapeHtml(tableData6976.id), '"', (opt_data.content.actual.table_id == tableData6976.id) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(tableData6976.item_code), ' (', soy.$$escapeHtml(tableData6976.party_size_range_display), ')</option>');
      }
      output.append('</select><select id="sac_to_tables_all" class="no-display"><option value=""></option>');
      var tableList6990 = opt_data.content.all_table_inventory_items;
      var tableListLen6990 = tableList6990.length;
      for (var tableIndex6990 = 0; tableIndex6990 < tableListLen6990; tableIndex6990++) {
        var tableData6990 = tableList6990[tableIndex6990];
        output.append('<option value="', soy.$$escapeHtml(tableData6990.id), '">', soy.$$escapeHtml(tableData6990.item_code), ' (', soy.$$escapeHtml(tableData6990.party_size_range_display), ')</option>');
      }
      output.append('</select>');
      var sac_tList7000 = opt_data.content.seatingareacodes_to_tables;
      var sac_tListLen7000 = sac_tList7000.length;
      for (var sac_tIndex7000 = 0; sac_tIndex7000 < sac_tListLen7000; sac_tIndex7000++) {
        var sac_tData7000 = sac_tList7000[sac_tIndex7000];
        if (sac_tData7000.tables.length > 0) {
          output.append('<select id="sac_to_tables_', soy.$$escapeHtml(sac_tData7000.id), '" class="no-display"><option value=""></option>');
          var tableList7006 = sac_tData7000.tables;
          var tableListLen7006 = tableList7006.length;
          for (var tableIndex7006 = 0; tableIndex7006 < tableListLen7006; tableIndex7006++) {
            var tableData7006 = tableList7006[tableIndex7006];
            output.append('<option value="', soy.$$escapeHtml(tableData7006.id), '">', soy.$$escapeHtml(tableData7006.item_code), ' (', soy.$$escapeHtml(tableData7006.party_size_range_display), ')</option>');
          }
          output.append('</select>');
        }
      }
      output.append('</div><div class="float-end"></div></div>');
    }
  }
  output.append('<div><div class="float-right supersubtext"> characters remaining: <span id=\'venue-notes-remaining\' class=\'number\' ></span></div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Notes:</div><div class="float-left w60"><textarea id="venue-notes-textarea" name="venue_notes" maxlength="150">', (opt_data.content.actual.notes) ? soy.$$escapeHtml(opt_data.content.actual.notes) : '', '</textarea></div><div class="float-end"></div></div></div></div><div class="save-links"><div class="float-right">');
  Nightloop.Templates.Widget.GoldButton({text: 'SAVE', size: 'medium', id: 'edit-partial-res-submit-btn'}, output);
  output.append('</div><div class="float-right"><a href="javascript:void(0);" id="edit-partial-res-cancel-btn">cancel</a></div></div></form><script type="text/javascript">$(document).ready( function() {var tableids_to_seatingareaids = {};');
  if (opt_data.content.actual.system_class == 'TABLE') {
    var sac_to_tList7030 = opt_data.content.seatingareacodes_to_tables;
    var sac_to_tListLen7030 = sac_to_tList7030.length;
    for (var sac_to_tIndex7030 = 0; sac_to_tIndex7030 < sac_to_tListLen7030; sac_to_tIndex7030++) {
      var sac_to_tData7030 = sac_to_tList7030[sac_to_tIndex7030];
      var tableList7031 = sac_to_tData7030.tables;
      var tableListLen7031 = tableList7031.length;
      for (var tableIndex7031 = 0; tableIndex7031 < tableListLen7031; tableIndex7031++) {
        var tableData7031 = tableList7031[tableIndex7031];
        output.append('tableids_to_seatingareaids[\'', soy.$$escapeHtml(tableData7031.id), '\'] = \'', soy.$$escapeHtml(sac_to_tData7030.id), '\';');
      }
    }
  }
  output.append('Pmp.Manager.Actual.PartialEdit.initialize( "', soy.$$escapeHtml(opt_data.venue.manager_base_url), '", "', soy.$$escapeHtml(opt_data.content.actual.id), '", tableids_to_seatingareaids);});<\/script>');
  return opt_sb ? '' : output.toString();
};
